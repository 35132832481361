




























import Vue, {PropType} from "vue";

export default Vue.extend({
  name: "summary-price" as string,

  props: {
    taxRatePercentage: String as PropType<string>,
    editLayout: Boolean as PropType<boolean>,
    price: String as PropType<string>,
    trialPeriod: Number as PropType<number>,
    paymentPeriodText: String as PropType<string>,
    noTax: Boolean as PropType<boolean>
  }
})
