
















import Vue from "vue";

export default Vue.extend({
  name: "streaming-device-modal" as string,

})
