





































import Vue, {PropType} from "vue";
import {SubscriptionProductsData, VenueData} from "@/types";
import { formatUnitPrice } from '@/utils/helpers';
import {mapGetters} from "vuex";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  name: "additional-services" as string,

  props: {
    registerLayout: Boolean as PropType<Boolean>,
    venue: Object as PropType<VenueData>,
    product: Object as PropType<SubscriptionProductsData>,
    paymentPeriod: String as PropType<string>,
    paymentPeriodText: String as PropType<string>,
    additionalServicesIds: Array as PropType<any>,
    noTax: Boolean as PropType<boolean>,
    taxInfo: String as PropType<string>,
    additionalOrder: String as PropType<string>
  },
  data() {
    return {
      audioMessages: false
    }
  },

  computed: {
    ...mapGetters("venueSubscriptionModule", {
      allSubscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS"
    }),
    locale(): string{
      return i18n.locale;
    },
    productName(): string {
      if (!this.product) return "";
      return this.locale == 'en' ? this.product.productName : (this.product.isProductNameTranslationCompleted ? this.product.productNameSk : this.product.productName);
    },
    productPrice(): string {
      // the price per month is always displayed, even if a variant of the year is selected
      if (!this.allSubscriptionProducts) return "";

      let productPrice = null;
      if(this.product.recurringInterval == "year") productPrice = (this.product.unitAmount / 12);
      else  productPrice = this.product.unitAmount;
      const taxMultiplier: number = this.noTax ? 1.2 : 1;

      if (!productPrice) return "";
      return formatUnitPrice(Number(productPrice));
    },
  },

  methods: {
    setAudioMessages(toggle: boolean = false): void {
      if (!toggle) this.audioMessages = !this.audioMessages;
      if (this.audioMessages) this.$emit("change", {id: this.product.id.toString(), unitAmount: this.product.unitAmount});
      if (!this.audioMessages) this.$emit('remove', {id: this.product.id.toString(), unitAmount: this.product.unitAmount});

    }
  },


  watch: {
    paymentPeriod: {
      handler(value: string): void {
        if (value) {
          this.setAudioMessages(true);
        }
      }
    },

    additionalServicesIds:{
      immediate: true,
      handler(value): void {
        if(value){
          if(!this.additionalServicesIds.length) this.audioMessages = false;
          for (const id of this.additionalServicesIds) {
            if(id == this.product.id) {
              this.audioMessages = true;
            }
          }
        }
      }
    },
    additionalOrder: {
      immediate: true,
      handler(value: string): void {
        if (value === 'true') {
          this.audioMessages = true
          this.setAudioMessages(true);
        }
      }
    },
  }
})
