





























import Vue, {PropType} from "vue";

export default Vue.extend({
  name: "checkbox-item" as string,
  props: {
    checked: Boolean as PropType<Boolean>,
    title: String as PropType<String>,
    description: String as PropType<String>,
    onClickItem: Function as PropType<Function>,
  }
});
