














































































































import Vue, { PropType } from "vue";
import { SubscriptionProductsData, VenueData } from "@/types";
import { formatUnitPrice } from "@/utils/helpers";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  name: "subscription-plan" as string,

  props: {
    venue: Object as PropType<VenueData>,
    product: Array as PropType<Array<SubscriptionProductsData>>,
    paymentPeriod: String as PropType<string>,
    paymentPeriodText: String as PropType<string>,
    editLayout: Boolean as PropType<boolean>,
    registerLayout: Boolean as PropType<boolean>,
    selectedProduct: Object as PropType<SubscriptionProductsData>,
    taxInfo: String as PropType<string>,
    noTax: Boolean as PropType<boolean>,
  },

  data() {
    return {
      circleCheck: require('@/assets/images/circle-check-subscribtion-plan-new.svg'),
      circleCross: require('@/assets/images/circle-cross-subscribtion-plan-new.svg'),
      subscriptionProduct: this.selectedProduct,
      billingPeriodicity: "month",
    }
  },

  async mounted(){
    await this.setSubscriptionDescriptions();
  },

  computed: {
    ...mapGetters("venueSubscriptionModule", {
      allSubscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS",
      paymentInterval: "GET_RECURRING_INTERVAL",
      basicMonthProduct: "GET_PRODUCT_BASIC_MONTH",
      premiumMonthProduct: "GET_PRODUCT_PREMIUM_MONTH"
    }),
    ...mapGetters("subscriptionDescriptionsModule", {
      allActiveSubscriptionsDescriptions: "GET_ACTIVE_SUBSCRIPTION_DESCRIPTIONS"
    }),
    ...mapGetters("venueModule", {
      createVenueLoader: "GET_CREATE_VENUE_LOADER"
    }),
    basicProduct(): SubscriptionProductsData {
      if (!this.product) return;
      return this.product.find(p => p.songorooPackageType === 1);
    },
    premiumProduct(): SubscriptionProductsData {
      if (!this.product) return;
      return this.product.find(p => p.songorooPackageType === 2);
    },
    basicProductPrice(): string {
      // the price per month is always displayed, even if a variant of the year is selected
      if (!this.allSubscriptionProducts) return "";
      let monthlySubscription = this.allSubscriptionProducts.find(s => s.songorooPackageType === 1 && s.recurringInterval === this.paymentInterval);

      const priceDivider: number = this.paymentInterval == 'year' ? 12 : 1;

      if (!monthlySubscription) return "";
      return formatUnitPrice(Number(monthlySubscription.unitAmount) / priceDivider);
    },
    premiumProductPrice(): string {
      // the price per month is always displayed, even if a variant of the year is selected
      if (!this.allSubscriptionProducts) return "";
      let monthlySubscription = this.allSubscriptionProducts.find(s => s.songorooPackageType === 2 && s.recurringInterval === this.paymentInterval);

      const priceDivider: number = this.paymentInterval == 'year' ? 12 : 1;

      if (!monthlySubscription) return "";
      return formatUnitPrice(Number(monthlySubscription.unitAmount / priceDivider));
    },
    premiumSubscriptionDescriptions(){
      if (!this.premiumProduct) return "";
      let filteredDescriptions = this.premiumProduct.subscriptionDescriptions.filter(description => description.deletedAt == null);
      let filteredAndSortedDescriptions = filteredDescriptions.sort((a, b) => {
        return b.id - a.id
      })
      return filteredAndSortedDescriptions;
    },
    basicSubscriptionDescriptions(){
      if (!this.basicProduct) return "";
      let filteredDescriptions = this.basicProduct.subscriptionDescriptions.filter(description => description.deletedAt == null);
      let filteredAndSortedDescriptions = filteredDescriptions.sort((a, b) => {
        return b.id - a.id
      })
      return filteredAndSortedDescriptions;
    },
    unAssignedBasicSubscriptionDescriptions(){
      if (!this.premiumSubscriptionDescriptions) return "";
      return this.premiumSubscriptionDescriptions.filter(({id: id1}) => !this.basicProduct.subscriptionDescriptions.some(({id: id2}) => id2 == id1))
    },
    locale(): string{
      return i18n.locale;
    },
    isBasicProductSelected(): boolean {
      return this.subscriptionProduct === this.basicProduct;
    },
    isPremiumProductSelected(): boolean {
      return this.subscriptionProduct === this.premiumProduct;
    },
    isSkVersion(): boolean {
      return this.$i18n.locale === 'sk';
    }
  },

  methods: {
    ...mapActions("venueSubscriptionModule", {
      setRecurringInterval: "SET_RECURRING_INTERVAL"
    }),
    ...mapActions("subscriptionDescriptionsModule", {
      setSubscriptionDescriptions: "FETCH_SUBSCRIPTION_DESCRIPTIONS"
    }),
    setSubscriptionProduct(value: SubscriptionProductsData): void {
      this.subscriptionProduct = value;
    },
    setBillingPeriodicity(): void {
      if (this.billingPeriodicity === 'month') {
        this.billingPeriodicity = 'year';
        this.setRecurringInterval('year');
      } else {
        this.billingPeriodicity = 'month';
        this.setRecurringInterval('month');
      }

      this.$emit("billingPeriodicityChanged")
    },
  },

  watch: {
    product: {
      handler(value: Array<SubscriptionProductsData>): void {
        if (value && !this.editLayout) {
          if (!this.subscriptionProduct) this.subscriptionProduct = this.premiumProduct;
        }
      },
      deep: true
    },
    selectedProduct: {
      handler(value: SubscriptionProductsData): void {
        if (value) this.subscriptionProduct = value;
      }
    },
    subscriptionProduct: {
      handler(value: SubscriptionProductsData): void {
        if (value) {
          this.$emit("change", value);
        }
      }
    },
    paymentPeriod: {
      handler(value: string): void {
        if (value) {
          if (this.subscriptionProduct.songorooPackageType === 1) this.setSubscriptionProduct(this.basicProduct);
          if (this.subscriptionProduct.songorooPackageType === 2) this.setSubscriptionProduct(this.premiumProduct);
        }
      }
    },
    paymentInterval: {
      immediate: true,
      handler(value: string): void {
        if (value) {
          this.billingPeriodicity = value;
        }
      }
    }
  }
})
