


































import Vue, { PropType } from "vue";
import { VenueData } from "@/types";
import { mapActions, mapGetters } from "vuex";
import CheckboxItem from "@/components/add-venue/CheckboxItem.vue";

export default Vue.extend({
  name: "payment-period" as string,

  props: {
    registerLayout: Boolean as PropType<ConstrainBooleanParameters>,
    venue: Object as PropType<VenueData>,
    paymentPeriod: String as PropType<string>,
  },
  components: {
    "checkbox-item": CheckboxItem,
  },
  data() {
    return {
      billingPeriodicity: "year",
    };
  },
  computed: {
    ...mapGetters("venueSubscriptionModule", {
      paymentInterval: "GET_RECURRING_INTERVAL",
    }),
  },
  methods: {
    ...mapActions("venueSubscriptionModule", {
      setRecurringInterval: "SET_RECURRING_INTERVAL",
    }),
    setBillingPeriodicity(value: string): void {
      if (
        (value === "year" && this.yearButtonDisabled) ||
        (value === "month" && this.monthButtonDisabled)
      ) {
        return;
      } else {
        this.billingPeriodicity = value;
        this.setRecurringInterval(value);
        this.$emit("billingPeriodicityChanged");
      }

      const periodicityInput: HTMLElement | null =
        document.getElementById("periodicityInput");

      if (periodicityInput !== null) {
        periodicityInput.click();
      }
    },
  },

  watch: {
    paymentInterval: {
      immediate: true,
      handler(value: string): void {
        if (value) {
          this.billingPeriodicity = value;
        }
      },
    },
  },
});
