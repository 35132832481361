




































import Vue, {PropType} from "vue";
import {VenueData} from "@/types";
import StreamingDeviceModal from "@/components/modals/StreamingDeviceModal.vue";
import CheckboxItem from "@/components/add-venue/CheckboxItem.vue";

export default Vue.extend({
  name: "music-streaming-method" as string,

  components: {
    "streaming-device-modal": StreamingDeviceModal,
    "checkbox-item": CheckboxItem,  
  },

  props: {
    registerLayout: Boolean as PropType<Boolean>,
    editLayout: Boolean as PropType<boolean>,
    venue: Object as PropType<VenueData>,
    selectedValue: Boolean as PropType<boolean>
  },

  data() {
    return {
      webstreamer: 0,
      activateModal: false
    }
  },

  methods: {
    setWebstreamer(value: number): void {
      this.webstreamer = value;
      if (!this.editLayout) {
        if (!value) {
          this.$bvModal.show("streaming-device-modal");
        }
      }
      if (this.editLayout && this.activateModal) {
        if (!value) {
          this.$bvModal.show("streaming-device-modal");
        }
      }
      this.$emit("change", value);
    }
  },

  watch: {
    selectedValue: {
      immediate: true,
      handler(value: boolean): void {
        const valueHandler: number = value ? 1 : 0;
        this.setWebstreamer(valueHandler);
        
        setTimeout(() => {
          if (!this.activateModal) this.activateModal = true;
        }, 3000);
        
      }
    }
  }
})
